import * as React from 'react';
import '../../App.css';
import { Navbar } from '../navbar/navbar';
import { CustomCarousel as Carousel } from '../carousel/carousel';
import { colors } from '../../assets/constants/colors';

/**
 * Describe the new component here...
 */
export const Header = () => {
  return (
    <header className='vshapedBottomDivider'>
      <div style={{ backgroundColor: '#00000099', paddingBottom: '30px' }}>
        <div
          className='flexed'
          style={{ justifyContent: 'space-evenly', alignItems: 'center' }}
        >
          <div className={'headerSection'}>
            <h1 style={{ color: '#ffffff99' }}>
              {'RYAN'}
              <span style={{ color: colors.primary }}>{' ZERNACH'}</span>
            </h1>
            <h2
              style={{
                color: '#ffffff75',
                fontSize: '18px',
                fontFamily: 'Regular',
              }}
            >
              {'AI Infrastructure & Mobile App Development'}
            </h2>
          </div>
          <div className={'headerSection'}>
            <img
              src={require('../../assets/images/other/Ryan_Zernach_2024_Mobile_App_Developer_React_Native_Remote_STEM_Tech_Network_iOS_Android_Miami_Beach.png')}
              className='app-icon'
              alt='Ryan_Zernach_2024_Mobile_App_Developer_React_Native_Remote_STEM_Tech_Network_iOS_Android_Miami_Beach'
              style={{}}
            />
          </div>
          <div className={'headerSection'}>
            <Carousel />
          </div>
        </div>
        <Navbar />
      </div>
    </header>
  );
};
